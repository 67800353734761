import React, {useState} from 'react';
import { useForm } from "react-hook-form";

export default function Form({isFeature, firstSelect, secondSelect, client, priority}) {
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [BPOV, setBPOV] = useState("")
    const [DOR, setDOR] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [result, setResult] = useState("")
    const [disabledButton, setDisabledButton] = useState(false)
    const [errMessage, setErrMessage] = useState("")
    const [file, setFile] = useState(null)
    const [fileObject, setFileObject] = useState(null)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const priorityMap = {
        "Alta": 3,
        "Media": 2,
        "Baja": 1
    }

    const changeHandler = (event) => {
        setFileObject(event.target.files[0])
        readFileDataAsBase64(event)
            .then(res => setFile(res))
    };

    function readFileDataAsBase64(e) {
        const file = e.target.files[0];

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(event.target.result);
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);
        });
    }

    const onSubmit = () => {
        if(!client) {
            setErrMessage("Seleccione un cliente,")
            return
        }
        if(!priority) {
            setErrMessage(",Seleccione una prioridad")
            return
        }
        let newBody = body + (BPOV ? + "\n" + " Punto de vista de negocio: " + BPOV : "") + (DOR ? + "\n" + " Definition of ready: " + DOR : "")
        setBody(newBody)
        setDisabledButton(true)
        let content = {
            title,
            "group": "Users",
            "cliente": client.toLowerCase().replace(" ", "_"),
            "prioridad": priorityMap[priority],
            "priority_id": priorityMap[priority],
            "customer_id": 8,
            "article": {
                "from": email,
                "subject": "Creado por: " + name ,
                "internal": false,
                "sender": "Customer",
                "type": "email",
                "body": newBody
            }
        }
        if(fileObject){
            content["article"]["attachments"] = [
                {"filename": fileObject["name"].replace(" ", "_"), "data": file.split(',')[1], "mime-type": "image/png"}
                ]
        }
        fetch(process.env.REACT_APP_HELP_DESK_API+ '/create_ticket', {
            method:'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(content)
        })
            .then((_) => { setResult("pass") })
            .catch((_) => { setResult("fail") })
    }

    return (
        <>
        <div className={"mt-2 grid grid-cols-4 place-content-center"}>
            <div>
            </div>
            <div>
                FORMULARIO DE {!isFeature ? 'ISSUES' : 'FEATURES'} OMNIBOT
            </div>
        </div>
            {!result ?
                <div className="grid grid-cols-2 divide-x">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="px-4 py-5 space-y-3 sm:p-6">
                            <label
                                className="block tracking-wide text-gray-700 text-xs font-bold "
                                htmlFor="grid-zip">
                                Cliente Omnibot
                            </label>
                            {firstSelect}
                            <h1 className={"text-red-500 text-xs"}>
                                {errMessage && errMessage.split(",")[0]}
                            </h1>
                            <div className={"mt-2 grid grid-cols-2 place-content-center"}>
                                <div>
                                    <label
                                        className="block tracking-wide text-gray-700 text-xs font-bold ">
                                        Nombre
                                    </label>
                                    <input
                                        id="name"
                                        name="name"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md pb-2 mb-2 pl-1 pt-1"
                                        placeholder="Nombre"
                                        defaultValue={''}
                                        {...register("name", {required: true})}
                                        onInput={(e) => setName(e.target.value)}
                                    />
                                    {errors.name?.type === 'required' &&
                                        <h1 className={"text-red-500 text-xs"}>
                                            Escribe tu nombre!
                                        </h1>
                                    }
                                </div>
                                <div className={"ml-2"}>
                                    <label
                                        className="block tracking-wide text-gray-700 text-xs font-bold ">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md pb-2 mb-2 pl-1 pt-1"
                                        placeholder="Correo"
                                        defaultValue={''}
                                        {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                                        onInput={(e) => setEmail(e.target.value)}
                                    />
                                    {errors.email?.type === 'required' &&
                                        <h1 className={"text-red-500 text-xs"}>
                                            Escribe tu email!
                                        </h1>
                                    }
                                </div>
                            </div>
                            <label
                                className="block tracking-wide text-gray-700 text-xs font-bold ">
                                Título
                            </label>
                            <div>
                                <input
                                    id="title"
                                    name="title"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md pb-2 mb-2 pl-1 pt-1"
                                    placeholder="Título"
                                    defaultValue={''}
                                    {...register("title", {required: true})}
                                    onInput={(e) => setTitle(e.target.value)}
                                />
                                {errors.title?.type === 'required' &&
                                    <h1 className={"text-red-500 text-xs"}>
                                        Un título es requerido!
                                    </h1>
                                }
                                <label
                                    className="block tracking-wide text-gray-700 text-xs font-bold mt-3">
                                    Descripción
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={4}
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-3 block w-full sm:text-sm border border-gray-300 rounded-md pl-1 pt-1"
                                    placeholder="Descripción"
                                    defaultValue={''}
                                    {...register("description", {required: true})}
                                    onInput={(e) => setBody(e.target.value)}
                                />
                                {errors.description?.type === 'required' &&
                                    <h1 className={"text-red-500 text-xs"}>
                                        Una descripción del error es requerida!
                                    </h1>
                                }
                                {isFeature &&
                                    <>
                                        <label
                                            className="block tracking-wide text-gray-700 text-xs font-bold mt-3">
                                            ¿Qué se espera desde el punto de vista del negocio?
                                        </label>
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows={4}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-3 block w-full sm:text-sm border border-gray-300 rounded-md pl-1 pt-1"
                                            placeholder="¿Qué se espera desde el punto de vista del negocio?"
                                            defaultValue={''}
                                            {...register("bpov", {required: true})}
                                            onInput={(e) => setBPOV(e.target.value)}
                                        />
                                        {errors.bpov?.type === 'required' &&
                                            <h1 className={"text-red-500 text-xs"}>
                                                Este campo es requerido!
                                            </h1>
                                        }
                                        <label
                                            className="block tracking-wide text-gray-700 text-xs font-bold mt-3">
                                            Definition of Ready
                                        </label>
                                        <textarea
                                            id="ready"
                                            name="ready"
                                            rows={4}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-3 block w-full sm:text-sm border border-gray-300 rounded-md pl-1 pt-1"
                                            placeholder="Definition of Ready"
                                            defaultValue={''}
                                            {...register("dor", {required: true})}
                                            onInput={(e) => setDOR(e.target.value)}
                                        />
                                        {errors.dor?.type === 'required' &&
                                            <h1 className={"text-red-500 text-xs"}>
                                                Este campo es requerido!
                                            </h1>
                                        }
                                    </>
                                }
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">Subir imagen
                                    referencial</label>
                                <div
                                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        {!fileObject ?
                                            <>
                                                <div className="flex text-sm text-gray-600">
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                    >
                                                        <span>Upload a file</span>
                                                        <input id="file-upload" name="file-upload" type="file"
                                                               className="sr-only" onChange={changeHandler}/>
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">PNG, JPG, GIF up to
                                                    10MB</p>
                                            </> :
                                            <p className="text-xs text-gray-500">{fileObject["name"]}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            {!disabledButton &&
                                <button
                                    type="submit"
                                    disabled={disabledButton}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Enviar
                                </button>
                            }
                        </div>
                    </form>
                    <div className={"border-none px-4 py-5 space-y-3 sm:p-6"}>
                        <label
                            className="block tracking-wide text-gray-700 text-xs font-bold "
                            htmlFor="grid-zip">
                            Prioridad
                        </label>
                        {secondSelect}
                        <h1 className={"text-red-500 text-xs"}>
                            {errMessage && errMessage.split(",")[1]}
                        </h1>
                    </div>
                </div>:
                <div className="h-56 grid grid-cols-4 gap-4 content-end">
                    <div className={"col-span-1"}>
                    </div>
                {result === "pass" &&
                    <div className={"col-span-1"}>
                    ✅ El ticket fue creado con éxito
                    </div>
                }
                {result === "fail" &&
                    <div className={"col-span-1"}>
                    ❌ Algo ha fallado, intente de nuevo en unos minutos
                    </div>
                }
                </div>
            }
        </>
    )
}