import './App.css';
import Form from "./components/Form";
import { useEffect, useState} from "react";
import OptionsSelect from "./components/OptionsSelect";


function App() {
    const [clients, setClients] = useState(["Bnc. Guayaquil", "Edenred", "HP", "Tessi"])
    const [priorities, setPriorities] = useState(["Alta", "Media", "Baja"])
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedPriority, setSelectedPriority] = useState("");

    useEffect(() => {
        fetch(process.env.REACT_APP_HELP_DESK_API+ '/clients', {
            method:'GET'})
            .then(response => response.json())
            .then(json => setClients(Object.values(json)))
            .catch(error => console.log(error))

        fetch(process.env.REACT_APP_HELP_DESK_API+ '/priorities', {
            method:'GET'})
            .then(response => response.json())
            .then(json => setPriorities(Object.values(json)))
            .catch(error => console.log(error))

    }, [])


    return (
        <div>
        { window.location.hostname.includes('issues') && <Form 
            firstSelect={<OptionsSelect values={clients} defaultText={"Cliente Omnibot"}
            selectedOption={selectedClient} setSelectedOption={setSelectedClient}/>}
            secondSelect={<OptionsSelect values={priorities} defaultText={"Prioridad"}
            selectedOption={selectedPriority} setSelectedOption={setSelectedPriority}/>}
            client={selectedClient} priority={selectedPriority}/> 
        }
        { window.location.hostname.includes('features') && <Form 
            isFeature={true} 
            firstSelect={<OptionsSelect values={clients} defaultText={"Cliente Omnibot"}
            selectedOption={selectedClient} 
            setSelectedOption={setSelectedClient}/>}
            secondSelect={<OptionsSelect values={priorities} defaultText={"Prioridad"}
            selectedOption={selectedPriority} setSelectedOption={setSelectedPriority}/>}
            client={selectedClient} priority={selectedPriority}/> 
        }
        </div>
    );
}

export default App;
